// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("/opt/build/repo/src/Templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-checkout-page-js": () => import("/opt/build/repo/src/Templates/CheckoutPage.js" /* webpackChunkName: "component---src-templates-checkout-page-js" */),
  "component---src-templates-order-detail-page-js": () => import("/opt/build/repo/src/Templates/OrderDetailPage.js" /* webpackChunkName: "component---src-templates-order-detail-page-js" */),
  "component---src-templates-sp-terms-of-service-page-js": () => import("/opt/build/repo/src/Templates/SpTermsOfServicePage.js" /* webpackChunkName: "component---src-templates-sp-terms-of-service-page-js" */),
  "component---src-templates-sp-privacy-policy-page-js": () => import("/opt/build/repo/src/Templates/SpPrivacyPolicyPage.js" /* webpackChunkName: "component---src-templates-sp-privacy-policy-page-js" */),
  "component---src-templates-sp-steps-page-js": () => import("/opt/build/repo/src/Templates/SpStepsPage.js" /* webpackChunkName: "component---src-templates-sp-steps-page-js" */),
  "component---src-templates-sp-login-page-js": () => import("/opt/build/repo/src/Templates/SpLoginPage.js" /* webpackChunkName: "component---src-templates-sp-login-page-js" */),
  "component---src-templates-select-cvs-page-js": () => import("/opt/build/repo/src/Templates/SelectCvsPage.js" /* webpackChunkName: "component---src-templates-select-cvs-page-js" */)
}

